






























import { Vue, Component } from 'vue-property-decorator'

import { RESEND_CONFIRMATION } from '@/store/auth'
import { PRODUCT_NAME } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Confirm email',
      }
    },
  },
})
export default class UnconfirmedEmail extends Vue {
  PRODUCT_NAME = PRODUCT_NAME

  loading = false

  async resend() {
    this.loading = true
    this.$store.dispatch(RESEND_CONFIRMATION, this.$route.query.email)

    this.loading = false
  }
}
